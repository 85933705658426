const lng = function() {
    return document.location.pathname.split('/')[1] || 'en';
};

export default [
    {
        name: 'Dashboard',
        path: '/' + lng() + '/webmaster/dashboard',
        icon: 'webmasterSidebarItemDashboard',
        external: false,
        isVisible: true,
    },
    /*    {
            name: 'News',
            path: '/en/webmaster/news',
            icon: 'webmasterSidebarItemNews',
            external: false,
        },*/
    {
        name: 'Offers',
        path: '/' + lng() + '/webmaster/offers',
        icon: 'webmasterSidebarItemOffers',
        external: false,
        isVisible: true,
    },
    {
        name: 'Streams',
        path: '/' + lng() + '/webmaster/streams',
        icon: 'webmasterSidebarItemStreams',
        external: false,
        isVisible: true,
    },
    {
        name: 'Domains',
        path: '/' + lng() + '/webmaster/domains',
        icon: 'webmasterSidebarItemSettings',
        external: false,
        isVisible: true,
    },
    {
        name: 'Statistics',
        path: '/' + lng() + '/webmaster/stat/session',
        icon: 'webmasterSidebarItemStatistics',
        external: false,
        isVisible: true,
    },
    {
        name: 'Lead Statistics',
        path: '/' + lng() + '/webmaster/stat/lead',
        icon: 'webmasterSidebarItemStatistics',
        external: false,
        isVisible: true,
    },
    {
        name: 'Ref Statistics',
        path: '/' + lng() + '/webmaster/stat/referral',
        icon: 'webmasterSidebarItemStatistics',
        external: false,
        isVisible: true,
    },
    {
        name: 'Payouts',
        path: '/' + lng() + '/webmaster/payouts',
        icon: 'webmasterSidebarItemPayouts',
        external: false,
        isVisible: true,
    },
    {
        name: 'Promocode',
        path: () => {
            let path = 'https://webvorkltd.zendesk.com/hc/ru/categories/360003201018-%D0%90%D0%BA%D1%86%D0%B8%D0%B8-%D0%B8-%D0%A1%D0%BA%D0%B8%D0%B4%D0%BA%D0%B8-%D0%BE%D1%82-%D0%BF%D0%B0%D1%80%D1%82%D0%BD%D1%91%D1%80%D0%BE%D0%B2-Webvork-Bonuses-and-Discounts-from-Webvork-partners';
            let language = document.location.pathname.split('/')[1];
            switch (language) {
                case 'ru':
                    path = 'https://webvorkltd.zendesk.com/hc/ru/categories/360003201018-%D0%90%D0%BA%D1%86%D0%B8%D0%B8-%D0%B8-%D0%A1%D0%BA%D0%B8%D0%B4%D0%BA%D0%B8-%D0%BE%D1%82-%D0%BF%D0%B0%D1%80%D1%82%D0%BD%D1%91%D1%80%D0%BE%D0%B2-Webvork-Bonuses-and-Discounts-from-Webvork-partners';
                    break;
                case 'en':
                    path = 'https://webvorkltd.zendesk.com/hc/ru/categories/360003201018-%D0%90%D0%BA%D1%86%D0%B8%D0%B8-%D0%B8-%D0%A1%D0%BA%D0%B8%D0%B4%D0%BA%D0%B8-%D0%BE%D1%82-%D0%BF%D0%B0%D1%80%D1%82%D0%BD%D1%91%D1%80%D0%BE%D0%B2-Webvork-Bonuses-and-Discounts-from-Webvork-partners';
                    break;
                default:
                    path = `https://webvorkltd.zendesk.com/hc/${language}`;
                    break;
            }
            return path;
        },
        icon: 'webmasterSidebarItemPromocode',
        external: true,
        isVisible: true,
    },
    {
        name: 'Support',
        path: '/' + lng() + '/webmaster/support',
        icon: 'webmasterSidebarItemSupport',
        external: false,
        isVisible: true,
    },
    {
        name: 'Webvorkshop',
        path: '/' + lng() + '/webmaster/shop',
        icon: 'webmasterSidebarItemWebvorkshop',
        external: false,
        isVisible: true,
    },
    /*{
        name: 'Webvorkshop',
        path: () => {
            let path = 'https://shop.webvork.com/en/';
            let language = document.location.pathname.split('/')[1];
            switch (language) {
                case 'ru':
                    path = WV.domainZone === 'ru' ? 'https://shop.webvork.ru/ru/' : 'https://shop.webvork.com/ru/';
                    break;
                default:
                    path = WV.domainZone === 'ru' ? 'https://shop.webvork.ru/en/' : 'https://shop.webvork.com/en/';
                    break;
            }
            return path;
        },
        icon: 'webmasterSidebarItemWebvorkshop',
        external: true,
        isVisible: true,
    },*/
    {
        name: 'Blog',
        path: () => {
            let path = '';
            let language = document.location.pathname.split('/')[1];
            switch (language) {
                case 'ru':
                    path = WV.domainZone === 'ru' ?  '#' : 'https://blog.webvork.com/ru/';
                    break;
                case 'en':
                    path = WV.domainZone === 'ru' ?  '#' : 'https://blog.webvork.com/en/';
                    break;
                default:
                    path = WV.domainZone === 'ru' ?  '#' : 'https://blog.webvork.com/en/';
                    break;
            }
            return path;
        },
        icon: 'webmasterSidebarItemBlog',
        external: true,
        isVisible: true,
    },
    {
        name: 'Support Bot',
        path: 'https://t.me/Webvork_team_bot',
        icon: 'webmasterSidebarItemTelegramBot',
        external: true,
        isVisible: (document.location.pathname.split('/')[1] === 'ru'),
    },
    {
        name: 'Help Center',
        path: () => {
            let path = 'https://webvorkltd.zendesk.com/hc/ru/categories/360002878017-%D0%A1%D0%BF%D1%80%D0%B0%D0%B2%D0%BE%D1%87%D0%BD%D1%8B%D0%B9-%D0%A6%D0%B5%D0%BD%D1%82%D1%80-%D0%B4%D0%BB%D1%8F-%D0%92%D0%B5%D0%B1%D0%BC%D0%B0%D1%81%D1%82%D0%B5%D1%80%D0%BE%D0%B2-Webmasters-Help-Center-';
            let language = document.location.pathname.split('/')[1];
            switch (language) {
                case 'ru':
                    path = 'https://webvorkltd.zendesk.com/hc/ru/categories/360002878017-%D0%A1%D0%BF%D1%80%D0%B0%D0%B2%D0%BE%D1%87%D0%BD%D1%8B%D0%B9-%D0%A6%D0%B5%D0%BD%D1%82%D1%80-%D0%B4%D0%BB%D1%8F-%D0%92%D0%B5%D0%B1%D0%BC%D0%B0%D1%81%D1%82%D0%B5%D1%80%D0%BE%D0%B2-Webmasters-Help-Center-';
                    break;
                case 'en':
                    path = 'https://webvorkltd.zendesk.com/hc/ru/categories/360002878017-%D0%A1%D0%BF%D1%80%D0%B0%D0%B2%D0%BE%D1%87%D0%BD%D1%8B%D0%B9-%D0%A6%D0%B5%D0%BD%D1%82%D1%80-%D0%B4%D0%BB%D1%8F-%D0%92%D0%B5%D0%B1%D0%BC%D0%B0%D1%81%D1%82%D0%B5%D1%80%D0%BE%D0%B2-Webmasters-Help-Center-';
                    break;
                default:
                    path = `https://webvorkltd.zendesk.com/hc/${language}`;
                    break;
            }

            return path;
        },
        icon: 'webmasterSidebarItemHelpCenter',
        external: true,
        isVisible: true,
    },
    {
        name: 'Settings',
        path: '/' + lng() + '/webmaster/settings',
        icon: 'webmasterSidebarItemSettings',
        external: false,
        isVisible: true,
    },
];

import {createRouter, createWebHistory} from 'vue-router'

const routes = [
    {
        path: '/',
        redirect: '/en/webmaster/dashboard'
    },
    {
        path: '/:lng/auth/login',
        name: 'webmaster.auth.login',
        component: () => import(/* webpackChunkName: "auth" */'../views/Webmaster/Auth/Login.vue'),
        meta: {
            isInternal: false,
            title: 'Login'
        }
    },
    {
        path: '/:lng/auth/register',
        name: 'webmaster.auth.register',
        component: () => import(/* webpackChunkName: "auth" */'../views/Webmaster/Auth/Register.vue'),
        meta: {
            isInternal: false,
            title: 'Register'
        }
    },
    {
        path: '/:lng/auth/recovery',
        name: 'webmaster.auth.recovery',
        component: () => import(/* webpackChunkName: "auth" */'../views/Webmaster/Auth/Recovery.vue'),
        meta: {
            isInternal: false,
            title: 'Recovery'
        }
    },
    {
        path: '/:lng/auth/recovery/:email/:token',
        name: 'webmaster.auth.recoveryFinal',
        component: () => import(/* webpackChunkName: "auth" */'../views/Webmaster/Auth/RecoveryFinal.vue'),
        meta: {
            isInternal: false,
            title: 'Recovery'
        }
    },
    {
        path: '/:lng/auth/activation',
        name: 'webmaster.activation',
        component: () => import(/* webpackChunkName: "auth" */'../views/Webmaster/Auth/Activation.vue'),
        meta: {
            isInternal: false,
            title: 'Activation'
        }
    },
    {
        path: '/:lng/auth/manager',
        name: 'webmaster.managerNotice',
        component: () => import(/* webpackChunkName: "auth" */'../views/Webmaster/Auth/Manager.vue'),
        meta: {
            isInternal: false,
            title: 'Manager'
        }
    },
    {
        path: '/:lng/auth/impersonate/:token',
        name: 'webmaster.auth.impersonate',
        component: () => import(/* webpackChunkName: "auth" */'../views/Webmaster/Auth/Impersonate.vue'),
        meta: {
            isInternal: false,
            title: ''
        }
    },
    {
        path: '/:lng/webmaster/poll',
        name: 'webmaster.poll',
        component: () => import(/* webpackChunkName: "poll" */'../views/Webmaster/Poll/Poll.vue'),
        meta: {
            isInternal: true,
            title: 'Poll'
        }
    },
    {
        path: '/:lng/webmaster/dashboard',
        name: 'webmaster.dashboard',
        component: () => import(/* webpackChunkName: "dashboard" */'../views/Webmaster/Dashboard/Dashboard.vue'),
        meta: {
            isInternal: true,
            title: 'Dashboard'
        }
    },
    {
        path: '/:lng/webmaster/news',
        name: 'webmaster.news',
        component: () => import(/* webpackChunkName: "news" */'../views/Webmaster/News/News.vue'),
        meta: {
            isInternal: true,
            title: 'News'
        }
    },
    {
        path: '/:lng/webmaster/domains',
        name: 'webmaster.domains',
        component: () => import(/* webpackChunkName: "domains" */'../views/Webmaster/Domains/Domains.vue'),
        meta: {
            isInternal: true,
            title: 'Domains'
        }
    },
    {
        path: '/:lng/webmaster/postback-logs',
        name: 'webmaster.postback.logs',
        component: () => import(/* webpackChunkName: "postbacklogs" */'../views/Webmaster/PostbackLogs/PostbackLogs.vue'),
        meta: {
            isInternal: true,
            title: 'Postback Logs'
        }
    },
    {
        path: '/:lng/webmaster/offers',
        name: 'webmaster.offers',
        component: () => import(/* webpackChunkName: "offers" */'../views/Webmaster/Offers/Offers.vue'),
        meta: {
            isInternal: true,
            title: 'Offers'
        }
    },
    {
        path: '/:lng/webmaster/streams',
        name: 'webmaster.streams',
        component: () => import(/* webpackChunkName: "streams" */'../views/Webmaster/Streams/Streams.vue'),
        meta: {
            isInternal: true,
            title: 'Streams'
        }
    },
    {
        path: '/:lng/webmaster/streams/create/:offer_id',
        name: 'webmaster.streams.create',
        component: () => import(/* webpackChunkName: "streams" */'../views/Webmaster/Stream/EditStream.vue'),
        meta: {
            isInternal: true,
            title: 'Create Stream'
        }
    },
    {
        path: '/:lng/webmaster/streams/edit/:stream_id',
        name: 'webmaster.streams.edit',
        component: () => import(/* webpackChunkName: "streams" */'../views/Webmaster/Stream/EditStream.vue'),
        meta: {
            isInternal: true,
            title: 'Edit Stream'
        }
    },
    {
        path: '/:lng/webmaster/streams/view/:stream_id',
        name: 'webmaster.streams.view',
        component: () => import(/* webpackChunkName: "streams" */'../views/Webmaster/StreamView/StreamView.vue'),
        meta: {
            isInternal: true,
            title: 'Stream'
        }
    },
    {
        path: '/:lng/webmaster/stat/referral',
        name: 'webmaster.stat.referral',
        component: () => import(/* webpackChunkName: "statref" */'../views/Webmaster/StatReferral/StatReferral.vue'),
        meta: {
            isInternal: true,
            title: 'Referral Statistics'
        }
    },
    {
        path: '/:lng/webmaster/stat/session',
        name: 'webmaster.stat.session',
        component: () => import(/* webpackChunkName: "statsession" */'../views/Webmaster/StatSession/StatSession.vue'),
        meta: {
            isInternal: true,
            title: 'Statistics'
        }
    },
    {
        path: '/:lng/webmaster/stat/lead',
        name: 'webmaster.stat.lead',
        component: () => import(/* webpackChunkName: "statlead" */'../views/Webmaster/StatLead/StatLead.vue'),
        meta: {
            isInternal: true,
            title: 'Lead Statistics'
        }
    },
    {
        path: '/:lng/webmaster/support',
        name: 'webmaster.support',
        component: () => import(/* webpackChunkName: "support" */'../views/Webmaster/Support/Support.vue'),
        meta: {
            isInternal: true,
            title: 'Support'
        }
    },
    {
        path: '/:lng/webmaster/shop',
        name: 'webmaster.webvorkshop',
        component: () => import(/* webpackChunkName: "webvorkshop" */'../views/Webmaster/Webvorkshop/Catalog/Catalog.vue'),
        meta: {
            isInternal: true,
            title: 'Webvorkshop'
        }
    },
    {
        path: '/:lng/webmaster/shop/product/:id',
        name: 'webmaster.shop.product',
        component: () => import(/* webpackChunkName: "webvorkshop" */'../views/Webmaster/Webvorkshop/Product.vue'),
        meta: {
            isInternal: true,
            title: 'Webvorkshop'
        }
    },
    {
        path: '/:lng/webmaster/shop/orders',
        name: 'webmaster.shop.orders',
        component: () => import(/* webpackChunkName: "webvorkshop" */'../views/Webmaster/Webvorkshop/Orders/Orders.vue'),
        meta: {
            isInternal: true,
            title: 'My orders'
        }
    },
    {
        path: '/:lng/webmaster/shop/checkout',
        name: 'webmaster.shop.checkout',
        component: () => import(/* webpackChunkName: "webvorkshop" */'../views/Webmaster/Webvorkshop/Checkout.vue'),
        meta: {
            isInternal: true,
            title: 'Checkout'
        }
    },
    {
        path: '/:lng/webmaster/payouts',
        name: 'webmaster.payouts',
        component: () => import(/* webpackChunkName: "payouts" */'../views/Webmaster/Payouts/Payouts.vue'),
        meta: {
            isInternal: true,
            title: 'Payouts'
        }
    },
    {
        path: '/:lng/webmaster/settings',
        name: 'webmaster.settings',
        component: () => import(/* webpackChunkName: "settings" */ '../views/Webmaster/Settings/Settings.vue'),
        meta: {
            isInternal: true,
            title: 'User Settings'
        }
    },
    {
        path: '/:pathMatch(.*)*',
        name: '404',
        component: () => import(/* webpackChunkName: "404" */ '../views/Webmaster/Errors/Error404.vue'),
        meta: {
            isInternal: true,
            title: 'Error 404'
        }
    },
]

const router = createRouter({
    history: createWebHistory(),
    mode: 'history',
    routes,
    scrollBehavior (to, from, savedPosition) {
        document.getElementById('app').scrollIntoView(({behavior: "smooth", block: "start", inline: "start"}))
    },
})

export default router
